<template>
  <div class="all">
    <div class="title">
      <span>他的其他供求</span>
    </div>
    <product-list-item v-for="item in list" :data="item" />
  </div>
</template>

<script>
import ProductListItem from '../layout/ProductListItem'

export default {
  name: 'OtherInfo',
  props: {
    list: Array,
  },
  data() {
    return {}
  },
  components: {
    ProductListItem,
  },
}
</script>

<style scoped>
.all {
  width: 800px;
  height: 460px;
}

.title {
  width: 100%;
  height: 55px;
  line-height: 55px;
  border-bottom: 1px solid #e6ebf5;
}

.title span {
  position: relative;
  top: 1px;
  display: inline-block;
  height: 55px;
  padding: 0 5px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #25449a;
  border-bottom: 2px solid #25449a;
}
</style>
