<template>
  <div class="all">
    <div class="one">
      <img :src="curryImg" />
      <div class="right">
        <div class="topInfo">
          <div style="width: 400px">
            <span>{{ data.title }}</span>
          </div>
          <div>
            <span>单价：</span>
            <span
              >¥<i>{{ data.price }}</i
              >/吨</span
            >
          </div>
          <div>
            <span>数量：</span>
            <span>{{ data.quantity }} 吨</span>
          </div>
          <div>
            <span>提货：</span>
            <span>{{ fdelivery }}</span>
          </div>
          <div>
            <span>地点：</span>
            <span>{{ data.location }}</span>
          </div>
          <div>
            <span>时间：</span>
            <span>{{ fdate }}</span>
          </div>
        </div>
        <div class="bottomInfo">
          <div class="bottomInfoLeft">
            <div>
              <img src="~assets/img/supplyMarket/user.png" />
              <span>{{ data.pabCustName }}</span>
            </div>
            <div>
              <img src="~assets/img/supplyMarket/anth.png" />
              <span>认证{{ data.userType }}</span>
            </div>
          </div>
          <div class="bottomInfoRight">
            <span>联系人</span>
            <span>{{ data.account }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="two">
      <img
        v-if="fimg.length > 0"
        @click="imgClk(item, i)"
        v-for="(item, i) in fimg"
        :src="imgUrl + item"
        :class="curryIndex == i"
      />
    </div>

    <div class="three">
      <span>{{ data.description }}</span>
    </div>

    <div class="four">
      <span
        >温馨提示：该货源未经同鑫核实，请谨慎交易，如涉及资金安全等问题，建议货款走平台监管，详情请致电客服</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailInfo',
  props: {
    data: Object,
  },
  data() {
    return {
      curryImg: '',
      curryIndex: 0,
    }
  },
  created() {
    if (this.fimg.length > 0) {
      this.curryImg = this.imgUrl + this.fimg[0]
    } else {
      this.curryImg = require('assets/img/public/noImg.png')
    }
  },
  methods: {
    imgClk(item, i) {
      this.curryImg = this.imgUrl + item
      this.curryIndex = i
    },
  },
  computed: {
    imgUrl() {
      return this.$config.supplyMarket.productImg
    },
    fimg() {
      let imgs = this.data.images
      if (imgs && imgs.length > 0) {
        return imgs.split(',')
      } else {
        return []
      }
    },
    fdelivery() {
      let del = this.data.delivery
      return del == '自提' ? '上门自提' : '送货上门'
    },
    fdate() {
      return this.$handle.dateFormat(this.data.created, 'm.d h:f')
    },
  },
}
</script>

<style scoped>
.all {
  width: 800px;
  height: 560px;
  padding: 20px;
  border: 1px solid #e6ebf5;
}

.one {
  display: flex;
  width: 100%;
}

.one > img {
  width: 350px;
  height: 300px;
}

.right {
  width: 400px;
  margin-left: 20px;
}

.topInfo {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  height: 230px;
  font-size: 14px;
  color: #000;
  border-bottom: 1px dashed #e6ebf5;
}

.topInfo div:nth-child(1) span {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}

.topInfo div:nth-child(2) span i {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  color: red;
  margin-right: 5px;
}

.bottomInfo {
  display: flex;
  align-items: center;
  width: 450px;
  height: 70px;
}

.bottomInfo img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.bottomInfoLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  height: 80%;
  font-size: 14px;
  color: #4c4c4c;
  border-right: 1px dashed #e6ebf5;
}

.bottomInfoRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  width: 50%;
  height: 80%;
  font-size: 14px;
  color: #808080;
}

.bottomInfoRight span:last-child {
  color: #25449a;
}

.two,
.three,
.four {
  width: 100%;
  max-height: 80px;
  overflow-y: auto;
  margin-top: 20px;
}

.two img {
  width: 68px;
  height: 68px;
  margin-right: 10px;
  cursor: pointer;
}

.three {
  padding-bottom: 10px;
  border-bottom: 1px dashed #e6ebf5;
}

.three span {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
}

.four {
  font-size: 14px;
  color: #ff4848;
}
</style>
