<template>
  <div class="all">
    <div class="tab w1400">
      <div v-for="(item, i) in tlist" class="tabDiv">
        <span :class="{ blue: item.isBlue }">{{ item.name }}</span>
        <Icon v-if="i != len" type="ios-arrow-forward" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabBar',
  props: {
    data: Object,
  },
  data() {
    return {
      tlist: [
        {
          name: '首页',
          isBlue: false,
        },
        {
          name: '供求市场',
          isBlue: this.data.tradeType == '出售',
        },
        {
          name: '货物采购',
          isBlue: this.data.tradeType == '求购',
        },
        {
          name: this.data.title,
          isBlue: true,
        },
      ],
    }
  },
  computed: {
    len() {
      return this.tlist.length - 1
    },
  },
}
</script>

<style scoped>
.all {
  width: 100%;
  height: 40px;
  background: #e6ebf5;
  color: #808080;
}
.tab {
  display: flex;
  font-size: 14px;
}

.tabDiv {
  line-height: 40px;
  margin-right: 10px;
}
.tabDiv span {
  margin-right: 10px;
}

.blue {
  color: #25449a;
}
</style>
