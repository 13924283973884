<template>
  <div class="all">
    <div class="title">
      <span>热门供求推荐</span>
    </div>
    <div class="content">
      <div v-for="item in list" @click="divClk(item)" class="contentDiv">
        <img :src="fimg(item.images)" />
        <div>
          <span style="margin-right: 5px">[{{ item.tradeType }}]</span>
          <span>{{ ftext(item.title) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OtherInfo',
  props: {
    list: Array,
  },
  methods: {
    fimg(str) {
      if (str && str.length > 0) {
        return this.imgUrl + str.split(',')[0]
      } else {
        return require('assets/img/public/noImg.png')
      }
    },
    ftext(str) {
      if(str.length >= 8) {
        return str.substr(0,8) + '...'
      } else {
        return str
      }
    },
    divClk(item) {
      let routeData = this.$router.resolve({
        path: '/supplyMarket/detail',
        name: 'SupplyMarketDetail',
        query: {
          quota: item.quota,
          account: item.account,
        },
      })
      window.open(routeData.href, '_blank')
    }
  },
  computed: {
    imgUrl() {
      return this.$config.supplyMarket.productImg
    },
  },
}
</script>

<style scoped>
.all {
  width: 800px;
  height: 460px;
}

.title {
  width: 100%;
  height: 55px;
  line-height: 55px;
  border-bottom: 1px solid #e6ebf5;
}

.title span {
  position: relative;
  top: 1px;
  display: inline-block;
  height: 55px;
  padding: 0 5px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #25449a;
  border-bottom: 2px solid #25449a;
}

.content {
  display: flex;
  justify-content: space-between;
}

.contentDiv {
  width: 180px;
  height: 200px;
  margin-top: 40px;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
}

.contentDiv > div {
  margin-top: 10px;
}

.contentDiv > img {
  width: 180px;
  height: 165px;
}
</style>
