<template>
  <div>
    <tab-bar v-if="isReady" :data="infoData1" />
    <div class="content w1400">
      <div class="left">
        <detail-info v-if="isReady" :data="infoData1" />
        <other-info v-if="isReady" :list="infoData2" class="mrTop36" />
        <hot-info v-if="isReady" :list="infoData3" class="mrTop36" />
      </div>
      <div class="right">
        <q-rcode />
        <scroll-box v-if="isReady" :option="transOpt1" class="mrTop20" />
        <scroll-box
          v-if="isReady"
          :option="transOpt2"
          @onClick="rollClick"
          class="mrTop20"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TabBar from './components/delLayout/TabBar'
import DetailInfo from './components/delLayout/DetailInfo'
import OtherInfo from './components/delLayout/OtherInfo'
import HotInfo from './components/delLayout/HotInfo'

import { getItemsInfo, getUserOrders } from 'network/supplyMarket'

export default {
  name: 'SupplyMarketDetail',
  data() {
    return {
      isReady: false,
      infoData1: {},
      infoData2: [],
      infoData3: [],
      transOpt1: {
        title: '成交动态',
        list: [],
        isHover: false,
        context: (str) =>
          `${str.contactName} 成功${str.tradeType}了${str.cateName}`,
      },
      transOpt2: {
        title: '最新供求',
        list: [],
        isHover: true,
        context: (str) => {
          let title = str.title
          if(title.length > 22) title = str.title.substr(0, 22) + '...'
          return `[${str.tradeType}] ${title}`
        },
      },
    }
  },
  created() {
    this.apiRes()
  },
  methods: {
    apiRes() {
      let apis = [this.getItemsInfo(), this.getUserOrders()]
      Promise.all(apis).then((res) => {
        this.isReady = true
      })
    },
    rollClick(e) {
      let routeData = this.$router.resolve({
        path: '/supplyMarket/detail',
        name: 'SupplyMarketDetail',
        query: {
          quota: e.quota,
          account: e.account,
        },
      })
      window.open(routeData.href, '_blank')
    },
    /* api相关 */
    getItemsInfo() {
      return new Promise((resolve) => {
        let data = {
          quota: this.$route.query.quota,
          account: this.$route.query.account,
          pageSize: 100,
        }
        getItemsInfo(data).then((res) => {
          if (res.code == 0) {
            this.infoData1 = res.data[0]
            this.infoData2 = res.data1.slice(0, 2)
            this.infoData3 = res.data2.slice(0, 4)
            this.transOpt2.list = res.data2
          }
          resolve()
        })
      })
    },
    getUserOrders() {
      getUserOrders().then((res) => {
        if (res.code == 0) {
          this.transOpt1.list = res.data.list
        }
      })
    },
  },
  components: {
    TabBar,
    DetailInfo,
    OtherInfo,
    HotInfo,
  },
}
</script>

<style scoped>
.mrTop20 {
  margin-top: 20px;
}
.mrTop36 {
  margin-top: 36px;
}
.content {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
</style>
